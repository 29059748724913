/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.0/dist/jquery.min.js
 * - /npm/popper.js@1.16.1/dist/umd/popper.min.js
 * - /npm/bootstrap@4.6.0/dist/js/bootstrap.min.js
 * - /npm/js-cookie@3.0.1/dist/js.cookie.min.js
 * - /npm/cleave.js@1.6.0/dist/cleave.min.js
 * - /npm/moment@2.29.1/min/moment.min.js
 * - /npm/moment@2.29.1/locale/sv.js
 * - /npm/swiper@4.4.6/dist/js/swiper.min.js
 * - /npm/flatpickr@4.6.9/dist/flatpickr.min.js
 * - /npm/flatpickr@4.6.9/dist/l10n/sv.js
 * - /npm/flatpickr@4.6.9/dist/plugins/confirmDate/confirmDate.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
